import React from "react"
import { useMutation } from "@apollo/client"
import { Query } from "@apollo/client/react/components"
import { RouteChildrenProps } from "react-router"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Switch from "@material-ui/core/Switch"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormGroup from "@material-ui/core/FormGroup"
import Divider from "@material-ui/core/Divider"
import Button from "@material-ui/core/Button"
import { useForm } from "react-hook-form"

import Layout from "../components/Layout"
import LoadingView from "../components/LoadingView"
import { DbRow } from "../../../common/db-typings"
import { ADMIN_LOCK_EVENT_DOWN_MUTATION, ADMIN_SQL_QUERY } from "../queries"
import { AdminSQLQuery, AdminSQLQueryVariables } from "../../../__generated__/AdminSQLQuery"
import { adminLockEventDown, adminLockEventDownVariables } from "../../../__generated__/adminLockEventDown"

interface ILockEventDownFormProps {
  event: DbRow
  awayTeam?: DbRow
  homeTeam?: DbRow
  mutate: any
  isSaving: boolean
}

const LockEventDownForm: React.FC<ILockEventDownFormProps> = ({ event, awayTeam, homeTeam, mutate, isSaving }) => {
  const [deleteCBSSpread, setDeleteCBSSpread] = React.useState<boolean>(false)
  const [deleteGameOdds, setDeleteGameOdds] = React.useState<boolean>(false)

  const { register, handleSubmit } = useForm()

  const onSubmit = async () => {
    await mutate({
      variables: {
        eventId: event.id,
        deleteCBSSpread: deleteCBSSpread,
        deleteGameOdds: deleteGameOdds,
      },
    })
  }

  const handleSetDeleteCBSSpread = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeleteCBSSpread(!!event.target.checked)
  }

  const handleSetDeleteGameOdds = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeleteGameOdds(!!event.target.checked)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography component="h5">
        Event - {awayTeam?.abbrev} @ {homeTeam?.abbrev}
      </Typography>
      <FormGroup row={true}>
        <FormControlLabel
          control={
            <Switch
              checked={!!deleteCBSSpread}
              onChange={handleSetDeleteCBSSpread}
              value={deleteCBSSpread}
              name={`deleteCBSSpread`}
              color="primary"
              inputRef={register}
            />
          }
          label="Delete CBS Spread"
        />
      </FormGroup>
      <FormGroup row={true}>
        <FormControlLabel
          control={
            <Switch
              checked={!!deleteGameOdds}
              onChange={handleSetDeleteGameOdds}
              value={deleteGameOdds}
              name={`deleteGameOdds`}
              color="primary"
              inputRef={register}
            />
          }
          label="Delete Game Odds"
        />
      </FormGroup>
      <Button variant="contained" color="primary" disabled={isSaving} type="submit">
        {isSaving ? `Saving...` : `Save`}
      </Button>
    </form>
  )
}

const LockEventDown: React.FC<RouteChildrenProps> = ({ location, history }) => {
  const [eventId, setEventId] = React.useState<number | undefined>(undefined)

  const handleSetEventId = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventId = Number(event?.target?.value)
    setEventId(eventId)
  }

  const [mutate, mutationResult] = useMutation<adminLockEventDown, adminLockEventDownVariables>(ADMIN_LOCK_EVENT_DOWN_MUTATION)

  return (
    <Layout title={`Scripts - Lock Game Down`}>
      <Typography component="h5">Set Event Id</Typography>
      <FormGroup row={true}>
        <TextField
          id="eventId"
          label="Event DB ID"
          type="number"
          className={undefined}
          onChange={handleSetEventId}
          margin="normal"
          name={"eventId"}
          size={"medium"}
          required={true}
        />
      </FormGroup>
      <Divider light={true} style={{ margin: "40px" }} />

      {(eventId && (
        <Query<AdminSQLQuery, AdminSQLQueryVariables>
          query={ADMIN_SQL_QUERY}
          variables={{ statement: `SELECT * from Event where Event.id = ${eventId}` }}
        >
          {(eventsQuery) => {
            const event = eventsQuery.data?.sql?.find((e) => e.id === eventId)
            return (
              <>
                {(event && (
                  <Query<AdminSQLQuery, AdminSQLQueryVariables>
                    query={ADMIN_SQL_QUERY}
                    variables={{ statement: `SELECT * from Team where Team.id = ${event.awayTeamId} or Team.id = ${event.homeTeamId}` }}
                  >
                    {(teamsQuery) => {
                      const teams = teamsQuery?.data?.sql
                      const awayTeam = teams?.find((t) => t.id === event.awayTeamId)
                      const homeTeam = teams?.find((t) => t.id === event.homeTeamId)
                      return (
                        <>
                          {homeTeam && awayTeam && (
                            <LockEventDownForm
                              event={event}
                              awayTeam={awayTeam}
                              homeTeam={homeTeam}
                              mutate={mutate}
                              isSaving={mutationResult.loading}
                            />
                          )}
                          {teamsQuery.loading && <LoadingView />}
                        </>
                      )
                    }}
                  </Query>
                )) || <Typography component="h6">Please enter an Event Id</Typography>}
                {eventsQuery.loading && <LoadingView />}
              </>
            )
          }}
        </Query>
      )) || <Typography component="h6">Please enter a Period Id</Typography>}
      <Typography component="h6">Please enter an Event Id</Typography>
    </Layout>
  )
}

export default LockEventDown
